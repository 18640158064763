// $font-2: "Bebas Neue", cursive;
// $font-1:  'Raleway', sans-serif;

$primary-color: hsl(0, 0%, 0%);
$secondary-color: hsl(0, 0%, 100%);
$teriary-color: #7e9aa6;
$quaternary-color: #84a1ad;

body {
  margin: auto;
  text-align: center;
  // background-color: hsla(0, 0%, 73%, 0.269);
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  font-family: "Barlow Condensed", sans-serif;
}

a {
  text-decoration: none;
  color: $primary-color;
}

ul {
  list-style-type: none;
}