#logo {
  width: 90px;
  margin: 0.2rem;
}
.navBar {
  position: relative;
}
.navbarFlex {
  // background-color: $secondary-color;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: solid;
}
.navPolice {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  transition: transform 0.2s;
  font-weight: 00;
}

.links a {
  margin: 0 2rem;
  transition: transform 0.2s;
}

.links a:hover {
  border-bottom: solid;
}

h1 {
  color: $teriary-color;
  margin: 0;
  opacity: 0.8;
  font-size: max(3vw, 30px);
}

.toggleButton {
  // height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: $primary-color;
  cursor: pointer;
}
#open {
  height: 100vh;
  background-color: $secondary-color;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  text-align: center;
}

#open .links {
  // margin-top: 500px;
  position: absolute;
  right: 10;
  top: 100px;
  // background-color: red;
  width: 90%;
  height: 300px;
  border: 1px solid black;
}
.toggleButton SVG {
  font-size: 50px;
}
@media only screen and (max-width: 975px) {
  .toggleButton {
    display: flex;
  }
  #close .links {
    display: none;
  }
  #open .links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // align-items: flex-start;
    align-content: space-around;
    margin: 1rem;
  }
  #logo {
    width: 60px;
    margin: 0.2rem;
  }
}

@media only screen and (min-width: 975px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
