.footer {
  width: 100%;
  background: linear-gradient($quaternary-color, $teriary-color);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  margin-top: 20px;
  color: $secondary-color;
  
}

.socialMedia svg {
  font-size: 40px;
  margin: 0 1rem;
  cursor: pointer;  
}

.navFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 0.2rem;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  margin: 0 0.2rem;
  font-size: 19px;
}
.navWidth {
  display: flex;
  justify-content: center;
}
.navColor {
  color: $secondary-color;
}
#copyright {
  color: $secondary-color;
}