.spectator {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  img {
    margin: 10px auto;
    display: block;
    width: 100%;
  }
  .txt {
    align-items: flex-start;
    text-align: left;
    h2 {
      margin: 1rem 2rem;
    }
    p {
      margin: 1rem 2rem;
      font-size: 18px;
    }
  }
}
.flexRow {
  display: flex;
  justify-content: space-between;
  color: $secondary-color;
  background-color: $teriary-color;
  width: 100%;
}

.italic {
  font-style: italic;
}
