.home {
    margin: auto;
    margin-top: 1rem;
    width: 95%;
    display: flex;
    justify-content: space-around;


}
.sendPost {
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.login {
    display: flex;
    justify-content: center;
}

Iframe {
    border: 0;
}