.atelier {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .txt {
    align-items: flex-start;
    text-align: left;
    h2 {
        margin: 1rem 2rem;
    }
    p {
        margin: 1rem 2rem;
        font-size: 18px;
    }
  }
}
