.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.roundedImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;

  .contentLeft {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem;
    text-align: left;
  }
  .rounded {
    margin-top: 1rem;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

.roundedImageRight {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 1rem;

  .contentRight {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 1rem;

    text-align: right;
  }
  .rounded {
    margin-top: 1rem;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

.teamVignette {
  display: flex;
  flex-direction: column;

  // justify-content: right ;
  // align-items: left;

  margin: 2rem;
  width: 80%;
}

@media only screen and (max-width: 900px) {
  .roundedImage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .roundedImageRight {
    display: flex;
    flex-direction: column;
    align-self: start;
    .contentRight {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 1rem;

      text-align: left;
    }
  }
}
