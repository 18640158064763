.workshop {
    margin:  2rem auto;
    width: 80%;
    border: 1px solid black;
}


.imgWorkshop {
    max-width: 50%;
}
