.top-to-btm{
  position: relative;
}
.top-to-btm svg {
  background-color: $teriary-color;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}


.icon-style:hover{
  animation: none;
  background: #fff;
  color: hsl(197, 37%, 24%);
  border: 2px solid hsl(197, 37%, 24%);
}