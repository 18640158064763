.map-container {
  display: flex;
  width: 100%;
      width: 100%;
    height: calc(100vh - 285px);
    margin:0;
    width: 100%;
    // border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.leaflet-map {
  flex: 1;
}

.info-panel {
  width: 50%;
  border-left: 1px solid #ccc;
  padding: 20px;
  overflow-y: auto;
}
.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}
.leaflet-marker-icon {
    filter: hue-rotate(20deg); /* Ce filtre change la teinte de l'image */
}

@media only screen and (max-width: 1000px) {
  .map-container  {
   height: calc(100vh - 254px);
  }
  .info-panel {
  width: 90%;
  border-left: 1px solid #ccc;
  padding: 20px;
  overflow-y: auto;
}
}
