.selectStyle {
  display: flex;
  select {
    width: 100%;
    margin: 1rem 0;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.sendPost {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espace entre les champs */
}

.sendPost input, .sendPost select, .sendPost textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sendPost input:focus, .sendPost select:focus, .sendPost textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.sendPost input[type="submit"] {
  background-color: #98AEB8;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sendPost input[type="submit"]:hover {
  background-color: #000000;
}

.buttonHorizontal {
  display: flex;
  justify-content: space-between;
}