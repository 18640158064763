.vignette {
  display: flex;
  flex-direction: column;
  border-radius: 8px; /* Match with vignette's border-radius */

  // justify-content: right ;
  // align-items: left;
  box-shadow: rgb(12, 12, 12) 0px 3px 8px;
  margin: 2rem;
  width: 50%;
  h1 {
    color: black;
    font-size: 34px; /* Adjust as needed */
    margin: 1rem 0;
  }
  p {
    margin: 0.4rem 0;
  }
}
.textProperty {
  white-space: pre-wrap;
  text-align: left;
}
.imgPost {
  width: 100%; /* Ensure the image fits the width of the container */
  height: 400px; /* Set a fixed height, adjust as needed */
  object-fit: cover; /* This will ensure the image covers the area without distortion */
  border-top-left-radius: 8px; /* Match with vignette's border-radius */
  border-top-right-radius: 8px;
}
Iframe {
  margin-top: 1rem;
   border-bottom-left-radius: 8px; /* Match with vignette's border-radius */
  border-bottom-right-radius: 8px; 
   object-fit: cover
}
.vignetteDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .vignetteTitle {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
  }
  .vignetteInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 1rem;
    p {
      font-size: 20px;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .vignette {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .vignette {
    width: 100%;
  }
}
